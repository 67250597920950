import React from "react"
import { Box, Button, Typography } from "@material-ui/core"
import Layout from "../components/layout/layout-home"
import { DigicLink } from "../components/digic-link"

const NotFoundPage = () => (
  <Layout>
    <Box
      pt="100px"
      display="flex"
      flexDirection="column"
      width="100vw"
      minHeight="80vh"
      alignItems="center"
      justifyContent="center"
    >
      <Typography variant="h1" style={{ fontSize: "72px" }}>
        404
      </Typography>
      <Typography variant="h4" style={{ marginBottom: "64px" }}>
        page not found
      </Typography>
      <DigicLink
        to="/"
        style={{
          display: "flex",
          justifyContent: "center",
          textDecoration: "none",
        }}
      >
        <Button variant="contained">back to home</Button>
      </DigicLink>
    </Box>
  </Layout>
)

export default NotFoundPage
